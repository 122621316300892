import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Tooltip, Box, Paper, TextField, Button, Typography, Dialog, DialogTitle, CircularProgress } from '@mui/material';
import {v4 as uuidv4} from 'uuid';


// const Root = styled(Container)({
//     // paddingTop: '10px',
//     // minHeight: 'fill-available',
//     flexGrow: '1 1',
//     // width: '100vw',
//     display: 'flex',
//     // justifyContent: 'space-between',
//     flexDirection: 'column',
//     backgroundColor: '#f2f2f2',
// });

const ChatBoxWrapper = styled(Paper)({
    overflowY: 'auto',
    flexGrow: 1,
    flex: '1 1 100px',
});

const QuestionBarWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const MessageWrapper = styled(Box)(({ theme, isUserMessage }) => ({
    maxWidth: '100%',
    borderRadius: '10px',
    padding: '10px',
    margin: '10px',
    position: 'sticky',
    backgroundColor: isUserMessage ? '#dcf8c6' : '#f2f2f2',
    alignSelf: isUserMessage ? 'flex-end' : 'flex-start',
}));

const QuestionInput = styled(TextField)({
    flex: 1,
    margin: '15px',
    marginRight: (theme) => theme.spacing(2),
});

const ChatBox = ({ messages }) => {
    return (
        <ChatBoxWrapper>
            {messages.map((message, index) => (
                <Message key={index} message={message} />
            ))}
        </ChatBoxWrapper>
    );
};

// const SourceWrapper = styled.div({
//     '&:hover': {
//         cursor: 'pointer',
//         textDecoration: 'underline',
//     },
// });

const Message = ({ message }) => {
    const isUserMessage = message.type === 'user';
    const messageText = message.text.replace(/\n\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

    return (
        <MessageWrapper isUserMessage={isUserMessage}>
            <Typography variant='body1'><b>{message.type === 'user' ? 'Usuário': 'IA LegalDoc'}</b></Typography> <br/>
            <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: messageText }} />
            {message.sources && message.sources.length > 0 && (
                <Typography variant="subtitle2" color='#aaaaaa'>
                    <br/><b>Fonte(s): </b> 
                    {message.sources.map((result, idx) => (
                        <Tooltip title={result.page_content} placement="bottom" key={idx}>
                            <br/>{result.source}
                        </Tooltip>
                    ))}
                </Typography>
            )}
        </MessageWrapper>
    );
};

const QuestionBar = ({ onQuestionSubmit, isLoading }) => {
    const [question, setQuestion] = useState('');

    const handleSubmit = (event) => {
            event.preventDefault();
            const response = onQuestionSubmit(question);
            // Aguarda o response para saber se tudo deu certo, se sim, limpa o campo de pergunta
            if (response)
                setQuestion('');
    };

    return (
            <QuestionBarWrapper component="form" onSubmit={handleSubmit}>
                    <Typography variant="body1">Pergunta:</Typography>
                    <QuestionInput
                            size='small'
                            variant="outlined"
                            style={{ backgroundColor: '#fff' }}
                            value={question}
                            onChange={(event) => setQuestion(event.target.value)}
                            disabled={isLoading}
                    />
                    <Button type="submit" variant="contained" color="primary" style={{  }} disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} /> : 'Enviar'}
                    </Button>
            </QuestionBarWrapper>
    );
};

const DocQA = ({ token, fileId }) => {
        const [messages, setMessages] = useState([]);
        const [error, setError] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        const [chatId] = useState(uuidv4());
        const [localFileId] = useState(fileId);

        const handleQuestionSubmit = async (question) => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/search/chat/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        question: question,
                        chatId: chatId,
                        fileId: localFileId
                    }),
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setMessages([...messages, { text: question, type: 'user' }, { text: data.message, sources: data.sources, type: 'system' }]);
                    
                } else {
                    const errorData = await response.json();
                    setMessages([...messages, { text: question, type: 'user' }, { text: 'Houve um erro ao efetuar a consulta. Tente novamente... Erro: ' + JSON.stringify(errorData), type: 'system' }]);
                    setError(errorData.message);
                }
            } catch (err) {
                setMessages([...messages, { text: question, type: 'user' }, { text: 'Houve um erro ao efetuar a consulta. Tente novamente... Erro: ' + err, type: 'system' }]);
                setError(err.message || "Algo deu errado.");
            } finally {
                setIsLoading(false);
            }
        };

        return (
                <Box style={{ display: 'flex', flexGrow: '1 1', height: '100%', flexDirection: 'column' }}>
                        <ChatBox messages={messages} />
                        <QuestionBar onQuestionSubmit={handleQuestionSubmit} isLoading={isLoading} />
                        {error && (
                                <Dialog open={true} onClose={() => setError(null)}>
                                <DialogTitle>{error}</DialogTitle>
                                </Dialog>
                        )}
                </Box>
        );
};

export default DocQA;
