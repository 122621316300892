import { useState } from 'react';
import { Typography, TextField, Button, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleLogin = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    props.loginHandler(data);
                    setSuccess(true);
                });
            } else {
                setError(true);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setError(true);
        });
    };

    const handleClose = () => {
        setError(false);
        setSuccess(false);
    };

    return (
        <Grid container justifyContent="center" alignItems="center" height="100vh" style={{ backgroundColor: '#f2f2f2' }}>
            <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{ marginRight: '5px', fontFamily: 'Courier New' }}>LEGAL_DOC</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Usuário" fullWidth value={username} onChange={e => setUsername(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Senha" type="password" fullWidth value={password} onChange={e => setPassword(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>Login</Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Typography variant="subtitle2" style={{ margin: '5px', color: '#aaaaaa' }}>By ADOY</Typography>
            </Grid>
            <Dialog open={error} onClose={handleClose}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <div>Usuário/senha incorretos.</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={success} onClose={handleClose}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <div>Login successful.</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default Login;
