import React, { useState } from 'react';
import {
  Button, Checkbox, FormControlLabel, TextField, Typography, Grid, Paper, CircularProgress, Dialog, DialogTitle
} from '@mui/material';
import DocText from './DocText';

const DocSearch = ({ token }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleFilterChange = (event) => {
    if (event.target.checked) {
      setSelectedFilters(prev => [...prev, event.target.name]);
    } else {
      setSelectedFilters(prev => prev.filter(filter => filter !== event.target.name));
    }
  };

  const handleDocClick = (doc) => {
    setSelectedDoc(doc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDoc(null);
  };

  const getIcon = (type, size) => {
    return <img src={`${type}.png`} alt={type} style={{ marginRight: '5px', verticalAlign: 'middle', width: size + 'px', height: size + 'px' }} />;
  };

  const handleSearch = async () => {
    setLoading(true);
    setResults(null);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/search/pesquisar/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          searchTerm: searchTerm,
          filters: selectedFilters,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setResults(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message);
      }
    } catch (err) {
      setError(err.message || "Algo deu errado.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="h5" style={{ marginRight: '5px' }}></Typography>
        <TextField
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          label="Pesquisar"
          variant="outlined"
          style={{width: '450px', backgroundColor: 'white'}}
        />
        <Button onClick={handleSearch} variant="contained" color="primary" style={{ marginLeft: '10px', height: '56px' }}>
          Pesquisar
        </Button>
      </div>
      <br/>
      <Grid container spacing={0} justifyContent="center" style={{ backgroundColor: 'white' }}>
        {[
          { name: 'WORD', label: 'Word' },
          { name: 'EXCEL', label: 'Excel' },
          { name: 'POWERPOINT', label: 'PowerPoint' },
          { name: 'PDF', label: 'PDF' },
          { name: 'EMAIL', label: 'E-mail' },
          { name: 'TEXTO', label: 'Texto' },
          { name: 'HTML', label: 'HTMLs' }
        ].map(filter => (
          <Grid item key={filter.name}>
            <FormControlLabel
              control={<Checkbox name={filter.name} onChange={handleFilterChange} />}
              label={<div style={{ display: 'flex', alignItems: 'left', verticalAlign:'middle' }}><span>{getIcon(filter.name, '20')}</span><Typography style={{ marginTop: '3px' }} variant="body2">{filter.label}</Typography></div>}
            />
          </Grid>
        ))}
      </Grid>
      {loading && <CircularProgress />}
      {!loading && results && results.length === 0 && <Typography variant="body1"><br></br>Nenhum documento encontrado<br></br></Typography>}
      {results && results.length > 0 && (
        <div style={{ marginTop: '20px', width: '800px', minWidth: '200px' }}>
          <Typography variant="body2" fontWeight="bold">{results.length} documento(s) encontrado(s)</Typography>
          {results.map((result, idx) => (
            <Paper key={idx} style={{ padding: '10px', marginTop: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'left' }}>
                <span>{getIcon(result.fileType, '28')}</span>
                <Typography variant="h6" style={{cursor: 'pointer'}} onClick={() => handleDocClick(result)}>{result.fileName}</Typography>
              </div>
              <Typography variant="body2">{result.snippet}</Typography>
            </Paper>
          ))}
        </div>
      )}
      {selectedDoc && (
        <Dialog open={open} onClose={handleClose} maxWidth="100%" fullWidth style={{ height: '100%' }} PaperProps={{ minHeight: '99%' }}>
          {/* <DialogTitle>{selectedDoc.fileName}</DialogTitle> */}
            <DocText fileId={selectedDoc.fileName} fileName={selectedDoc.fileName} token={token} fileType={selectedDoc.fileType} />
        </Dialog>
      )}
      {error && (
        <Dialog open={true} onClose={() => setError(null)}>
          <DialogTitle>{error}</DialogTitle>
        </Dialog>
      )}
    </div>
  );
};

export default DocSearch;
