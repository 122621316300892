import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Box, DialogContentText, DialogTitle, LinearProgress, Input, Paper, Typography, Grid } from '@mui/material';
import axios from 'axios';
import DocText from './DocText';


const DocUpload = ({token}) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setUploadSuccess(null); // reset upload success message
    };

    const getIcon = (type) => {
        return <img src={`${type}.png`} alt={type} style={{ marginRight: '5px', marginTop: '3px', verticalAlign: 'middle', width: '20px', height: '20px' }} />;
      };    

    const handleUpload = async () => {
        if (!file) {
            return;
        }

        setConfirmDialogOpen(false);
        setUploading(true);

        const formData = new FormData();
        formData.append('file', file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
                setTimeRemaining(Math.ceil((progressEvent.total - progressEvent.loaded) / (progressEvent.loaded / progressEvent.timeStamp)));
            }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/search/upload`, formData, config);
            console.log(response.data);
            setSelectedDoc(response.data.doc_info);
            setFile(null);
            setProgress(0);
            setTimeRemaining(null);
            setUploadSuccess(true);
        } catch (error) {
            console.error(error);
            setUploadSuccess(false);
        } finally {
            setUploading(false);
        }
    };

    const handleDocClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmDialogClose = (confirmed) => {
        if (confirmed) {
            handleUpload();
        } else {
            setConfirmDialogOpen(false);
        }
    };

    return (
        <>
            <Paper sx={{ p: 2, display: 'flex', flex: '0 0', flexDirection: 'column', margin: '20px' }}>
            <Typography variant="h5" component="h5" gutterBottom>Upload de Documento.</Typography>
                <Typography variant="body1" component="body1" gutterBottom style={{ backgroundColor: '#f2f2f2', padding: '5px' }}>Escolha um arquivo do seu computador para ser indexado ao serviço LegalDoc.</Typography>
                <br/>
                <Typography variant="body1" component="body1" gutterBottom>Os arquivos podem ser do tipo:<br/>
                <ul>
                    <li>
                        {getIcon('WORD')} Microsoft Word (.docx, .doc)
                    </li>
                    <li>
                        {getIcon('EXCEL')} Microsoft Excel (.xlsx, .xls) <br/>
                    </li>
                    <li>
                        {getIcon('POWERPOINT')} Microsoft PowerPoint (.pptx, .ppt)<br/>
                    </li>
                    <li>
                        {getIcon('PDF')} Arquivo PDF (.pdf)<br/>
                    </li>
                    <li>
                        {getIcon('HTML')} Página Html (.html)<br/>
                    </li>
                    <li>
                        {getIcon('TEXTO')} Arquivo de Texto (.txt)<br/>
                    </li>
                    <li>
                        {getIcon('EMAIL')} Email (.msg)<br/>
                    </li>
                </ul>
                </Typography>
                <Grid style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f2f2f2', flex: '1 1', margin: '20px', padding: '10px', alignItems: 'center' }}>
                    <Input type="file" onChange={handleFileChange} style={{ flexGrow: 1 }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" disabled={!file || uploading} onClick={handleUpload}>
                        {uploading ? <CircularProgress size={24} /> : 'Enviar'}
                    </Button>
                </Grid>
                {uploading && (
                    <>
                        <LinearProgress variant="determinate" value={progress} />
                        <DialogContent>
                            <DialogContentText>
                                Tempo estimado restante: {timeRemaining ? `${timeRemaining} segundos` : 'calculando...'}
                            </DialogContentText>
                        </DialogContent>
                    </>
                )}
                {uploadSuccess === true && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flex: '1 1', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="subtitle1" sx={{ color: 'green', fontWeight: 'bold', flex: '1 1', alignSelf: 'center', margin: '10px' }}>Arquivo enviado com sucesso! Seu documento foi enviado e indexado com sucesso!</Typography>
                        <Button color='primary' variant="contained" onClick={() => handleDocClick()}>Conversar com o documento</Button>
                        {open && (
                            <Dialog open={open} onClose={handleClose} maxWidth="100%" fullWidth style={{ height: '100%' }} PaperProps={{ minHeight: '99%' }}>
                                <DocText fileId={selectedDoc.file_id} fileName={selectedDoc.file_name} token={token} fileType={selectedDoc.file_type} />
                            </Dialog>
                        )}
                    </Box>
                )}
                {uploadSuccess === false && (
                    <Typography variant="subtitle1" sx={{ color: 'red', fontWeight: 'bold', flex: '1 1', alignSelf: 'center', margin: '10px' }}>Ocorreu um erro ao enviar o arquivo. Tente novamente mais tarde.</Typography>
                )}
                <Dialog open={confirmDialogOpen} onClose={() => handleConfirmDialogClose(false)}>
                    <DialogTitle>Upload em andamento</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            O upload ainda não terminou e pode falhar se você sair da página. Tem certeza que deseja sair?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleConfirmDialogClose(false)}>Cancelar</Button>
                        <Button onClick={() => handleConfirmDialogClose(true)}>Sair</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
};

export default DocUpload;
