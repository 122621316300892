
import { Typography, Box, IconButton, Toolbar, AppBar, ListItemButton, ListItem } from '@mui/material';
import { Drawer, List, ListSubheader, ListItemIcon, ListItemText } from '@mui/material';
import { Menu, Search, Chat, Upload } from '@mui/icons-material';
import { useState } from 'react';
import DocSearch from './components/DocSearch';
import ChatQA from './components/ChatQA';
import Login from './components/Login';
import DocUpload from './components/DocUpload';
import Welcome from './components/Welcome';

function App() {
  const [showDocSearch, setShowDocSearch] = useState(false);
  const [showChatQA, setShowChatQA] = useState(false);
  const [docUpload, setDocUpload] = useState(false);
  const [logged, setLogged] = useState(false);
  const [token, setToken] = useState(null);
  const [showMenu, setShowMenu] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleDocSearchClick = (event, index) => {
    setShowChatQA(false);
    setShowDocSearch(true);
    setDocUpload(false);
    setShowMenu(false);
    setSelectedIndex(index);
  };

  const handleDocQAClick = (event, index) => {
    setShowDocSearch(false);
    setShowChatQA(true);
    setDocUpload(false);
    setShowMenu(false);
    setSelectedIndex(index);
  };

  const handleDocUpload = (event, index) => {
    setShowDocSearch(false);
    setShowChatQA(false);
    setDocUpload(true);
    setShowMenu(false);
    setSelectedIndex(index);
  };

  const handleLogin = (request) => {
    setToken(request.token);
    setLogged(true);
  }

  const handleMenuOpen = () => {
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  return (
    <Box sx={{ flexGrow: 1, flex: '1 1', display: 'flex', height: '100vh', justifyContent: 'space-between', flexDirection: 'column' }}>
      {logged && (
        <>
          <AppBar position="static" style={{ maxHeight: 'content', flex: '0' }}>
            <Toolbar>
              <IconButton color="inherit" aria-label="open menu" edge="start" onClick={handleMenuOpen}>
                <Menu />
              </IconButton>
              <Typography variant="div" component="div" sx={{ fontFamily: 'Courier New', fontSize: '22px', flexGrow: 1 }}>
                LEGAL_DOC
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer anchor="left" open={showMenu} onClose={handleMenuClose}>
            
            <List style={{ backgroundColor: '#fafafa' }}>
              <ListItem style={{ color: '#0956b2', marginLeft: '6px', marginTop: '6px', width: 'auto' }}><Typography variant="h5" style={{ fontFamily: 'Courier New', alignSelf: 'center', alignContent: 'center' }}>LEGAL_DOC</Typography></ListItem>
              <ListItem divider />
              <ListSubheader>AGENTE (IA)</ListSubheader>
              <ListItemButton onClick={(event) => handleDocQAClick(event, 1)} selected={selectedIndex === 1}>
                <ListItemIcon style={{ minWidth: '36px' }}>
                  <Chat />
                </ListItemIcon>
                <ListItemText variant="subtitle2" primary="Chat com documentos" />
              </ListItemButton>

              <ListSubheader>PESQUISA CONTEXTUAL</ListSubheader>
              <ListItemButton onClick={(event) => handleDocSearchClick(event, 0)} selected={selectedIndex === 0}>
                <ListItemIcon style={{ minWidth: '36px' }}>
                  <Search />
                </ListItemIcon>
                <ListItemText primary="Pesquisar" />
              </ListItemButton>
                            
              <ListSubheader>INDEXAR DOCUMENTOS</ListSubheader>
              <ListItemButton onClick={(event) => handleDocUpload(event, 2)} selected={selectedIndex === 2}>
                <ListItemIcon style={{ minWidth: '36px' }}>
                  <Upload />
                </ListItemIcon>
                <ListItemText primary="Subir novo documento" />
              </ListItemButton>
            </List>
          </Drawer>
        </>
      )}
      {!logged && (
        <Login loginHandler={handleLogin} />
      )}
      {logged && (
        <Box sx={{ flexGrow: 1, backgroundColor: '#f2f2f2', display: 'flex', flexDirection: 'column' }}>
          {showDocSearch && (
            <DocSearch token={token} />
          )}
          {showChatQA && (
            <Box sx={{ flexGrow: 1, flex: '1 1', display: 'flex', flexDirection: 'column', height: '100%', marginTop: '30px', marginLeft: '30px', marginRight: '30px' }}>
              <ChatQA token={token} filename="" />
            </Box>
          )}
          {docUpload && (
            <DocUpload token={token} />
          )}
          {!showDocSearch && !showChatQA && !docUpload && (
            <Welcome chatHandler={handleDocQAClick} searchHandler={handleDocSearchClick} uploadHandler={handleDocUpload} />
          )}
        </Box>
      )}
    </Box>
  );
}

export default App;

