import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { Search, Chat, Upload } from '@mui/icons-material';


const Welcome = ({ uploadHandler, searchHandler, chatHandler }) => {
    
    const ToolButton = ({title, description, icon, handler}) => {
        return (
            <Paper sx={{ width: 'auto', cursor: 'pointer' }} onClick={() => handler()}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box style={{ padding: '20px' }}>
                        {icon}
                    </Box>
                    <Box sx={{ flexDirection: 'column', paddingTop: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
                        <Typography variant='h6'>{title}</Typography>
                        <Typography variant='body2'>{description}</Typography>
                    </Box>
                </Box>
            </Paper>
       )
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flex: '1 1', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#f2f2f2' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '500px', height: '400px' }}>
                <Typography variant='h6' style={{ fontFamily: 'Courier New' }}>Seja bem vindo ao LEGAL_DOC.</Typography>
                <br/><ToolButton title="Indexar novo documento" description="Enviar um novo documento para o sistema. Isto permitirá pesquisas contextuais e conversas com seu documento." icon={<Upload/>} handler={uploadHandler} />
                <br/><ToolButton title="Pesquisa Contextual" description="Pesquise por documentos sem a necessidade do uso de palavras-chave. Digite livremente o que gostaria de pesquisar. Quanto mais descritivo, melhores serão os resultados." icon={<Search/>} handler={searchHandler} />
                <br/><ToolButton title="Chat com documentos" description="Utilize Inteligência Artificial de ponta para lhe auxiliar com consultas em seus documentos." icon={<Chat/>} handler={chatHandler} />
                <br/><Typography variant='body2' style={{ fontFamily: 'Courier New' }}>*Utilize o menu para mais opções</Typography>
            </Box>
        </Box>
    );
};

export default Welcome;
