import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, CircularProgress, Button, Paper } from '@mui/material';
import ChatQA from './ChatQA';

const DocText = ({ fileId, fileName, fileType, token }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [text, setText] = useState('');
    const [localFileId] = useState(fileId);

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    };
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/doc_content?file_id=${localFileId}`, config);
            console.log(response)
            var t = response.data.content.replace(/\n\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
            setText(t);
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                };
            
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/search/doc_content?file_id=${localFileId}`, config);
                console.log(response)
                var t = response.data.content.replace(/\n\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
                setText(t);
                setLoading(false);
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchData();
    }, [localFileId, token]); //, localFileId]);

    const handleRetry = () => {
        setLoading(true);
        setError(false);
        setText('');

        fetchData();
    };

    const getIcon = (type, size) => {
        return <img src={`${type}.png`} alt={type} style={{ marginRight: '5px', verticalAlign: 'middle', width: size + 'px', height: size + 'px' }} />;
      };
    
    return (
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%', height: '100%', backgroundColor: '#f2f2f2' }}>
            <Typography variant="h1" sx={{ fontSize: '20px', padding: '10px', backgroundColor: 'white' }}>
                {getIcon(fileType, '24')}{fileName}
            </Typography>
            {/* <!-- style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', margin: '30px' }} --> */}
            {/* <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', margin: '30px' }}> */}
            <Box style={{ display: 'flex', flexGrow: 2, flex: '1 1', flexDirection: 'row', margin: '30px' }}>
                <Box style={{ display: 'flex', flexGrow: 1, flex: '1 1', justifyContent: 'space-between' }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1, flex: '1 1', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : error ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flex: '1 1', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography>Algo deu errado. Verifique sua internet e tente novamente.</Typography>
                            <Button variant="contained" color="primary" onClick={handleRetry}>Tentar novamente</Button>
                        </Box>
                    ) : (
                        // style={{ display: 'flex', overflowY: 'scroll', flexGrow: 1, flex: '1 1' }}
                        <Paper 
                        sx={{
                            // mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            // maxHeight: "100%",
                            height: '80vh',
                            // flex: '1 1',
                            overflow: "hidden",
                            overflowY: "scroll",
                           // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                          }}
                        >
                            <Typography variant='body2' style={{ 'padding': '15px' }} dangerouslySetInnerHTML={{ __html: text }} ></Typography>
                        </Paper>
                    )}
                </Box>
                <Box sx={{ paddingLeft: '30px', flexGrow: '0 1', width: '400px' }}>
                    <ChatQA fileId={localFileId} token={token} />
                </Box>
            </Box>
        </Box>
    );
};

export default DocText;
